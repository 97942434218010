<template>
    <div>
        <q-tree
            ref="domTree"
            :nodes="HospTree"
            node-key="HospID"
            label-key="HospName"
            children-key="Children"
            no-connectors
            selected-color="primary"
            v-model:ticked="selectedHospIDs"
            default-expand-all
            tick-strategy="leaf"
        >
        </q-tree>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    computed: {
        ...mapState("SysHosp", ["HospTree"]),
        ...mapState("SysUser", ["ShowAuth","UserEntity"]),
        ...mapState("SysHospUser", ["SelectedHospIDs"]),

        selectedHospIDs: {
            get() {
                return this.$store.state.SysHospUser.SelectedHospIDs;
            },
            set(val) {
                console.log(val);
                this.$store.commit("SysHospUser/setSelectedHospIDs",val);
            },
        },
    },
    watch: {
        ShowAuth: {
            handler(val) {
                if (val) {
                    this.actLoadHospTree();
                    this.actLoadHospUserListByUserID({UserID:this.UserEntity.UserID});
                }
            },
            immediate: true,
            deep: true,
        },
    },
    data() {
        return {};
    },
    methods: {
        ...mapActions("SysHosp", ["actLoadHospTree"]),
        ...mapActions("SysHospUser", ["actLoadHospUserListByUserID"]),
        //加载机构树
        onLoadHospTree() {
            this.actLoadHospTree();
        },
    },
};
</script>